// custom.scss
@import url('https://fonts.cdnfonts.com/css/revans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
  --skyblue: #4ed5ff;
  --skybluelight: #c6dff1;
  --gray: #8c8c8c;
  --cerulean: #40a6dd;
  --cerulean-hover: #ebf3fd;
  --denim: #2f65af;

  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

.whatsapp-float .whatsapp-icon {
  margin-top: 16px;
}

.container-fluid {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.small-text {
  font-size: 12px;
}

body {
  font-family: 'Revans', 'Open Sans', sans-serif;
}

.bg-skyblue {
  background-color: var(--skyblue);
  color: #ffffff; /* Cor do texto para contraste */
}

.bg-skybluelight {
  background-color: var(--skybluelight);
  color: #000000; /* Cor do texto para contraste */
}

.bg-gray {
  background-color: var(--gray);
  color: #ffffff; /* Cor do texto para contraste */
}

.bg-cerulean {
  background-color: var(--cerulean);
  color: #ffffff; /* Cor do texto para contraste */
}

.bg-denim {
  background-color: var(--denim);
  color: #ffffff; /* Cor do texto para contraste */
}

.bg-denim-opacity {
  background-color: rgba(47, 101, 175, 0.8) !important;
}

.bg-branco-opacity {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bg-preto-opacity {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.text-skyblue {
  color: var(--skyblue); /* Cor de texto correspondente */
}

.text-skybluelight {
  color: var(--skybluelight); /* Cor de texto correspondente */
}
.text-gray {
  color: var(--gray); /* Cor de texto correspondente */
}
.text-cerulean {
  color: var(--cerulean); /* Cor de texto correspondente */
}

.text-denim {
  color: var(--denim); /* Cor de texto correspondente */
}

/* MUDANÇAS NO MENU */

.navbar-nav .nav-link,
.dropdown-menu .dropdown-item {
  color: var(--denim); /* Cor inicial do texto */
  transition: background-color 0.3s ease;
}

.navbar-nav .nav-link:hover,
.dropdown-menu .dropdown-item:hover {
  background-color: var(--cerulean-hover);
}

/* MUDANÇAS NO CAROUSEL */

.carousel-item {
  height: 400px; /* Define a altura do carousel */
}
.carousel-item img {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Garante que a imagem cubra completamente o carousel */
}


